
$(document).ready(function() {
  function validateShowPresence(){
    let cookies = document.cookie;
    if(!cookies.includes('shop_presence')){
      var isSecure = location.protocol === 'https:';
      Cookies.set('shop_presence', true, { expires: 1, secure: isSecure });
    }
  }

  validateShowPresence();
});